import * as types from '../mutation-types'
import mailRepository from '../../api/mailRepository'

const state = {
    mailToSend: {},
    isMailSent: false,
}

const getters = {
    mailToSend: state => state.mailToSend,
    isMailSent: state => state.isMailSent,
}

const actions = {
    sendContactMail({ commit }) {
        return mailRepository.sendContactMail(state.mailToSend).then((response) => {
            if (response.data.success) {
                commit(types.SET_MAIL_IS_SENT, true)
            } else {
                commit(types.SET_MAIL_IS_SENT, false)
            }
        })
    }
}

const mutations = {
    [types.SET_MAIL_TO_SEND](state, payload) {
        state.mailToSend = payload
    },
    [types.SET_MAIL_IS_SENT](state, payload) {
        state.isMailSent = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
