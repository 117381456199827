import Vue from 'vue';
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue';
import router from './router'
import store from './store'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(Notifications, { velocity })

Vue.config.productionTip = true;

console.log('env', JSON.stringify(process.env))

new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    render: h => h(App)
})
