<template>
    <div class="about">
        <h1>About</h1>
    </div>
</template>

<script>
    export default {
        name: 'About'
    };
</script>

<style scoped>
</style>