<template>
    <b-container>
        <b-row>
            <b-col>
                <h1>WWWebsolutions</h1>
                <h2>De ideale webshop voor de technische groothandel</h2>
                <p>Een technische groothandel of fabrikant heeft vaak een zeer omvangrijk assortiment met vele duizenden artikelen. Een overzichtelijke en snelle website, is daarom voor de technische handelsbedrijven ontzettend belangrijk. Met onze uitgebreide kennis op het gebied van de grote assortimenten en onze weboplossingen speciaal voor de groothandels en fabrikanten, kunnen wij een uitgebreide website opzetten die aan alle wensen voldoet.</p>
                <h2>Veel producten met al hun technische kenmerken, overzichtelijk getoond</h2>
                <p>Bij een website met een assortiment van 10.000 artikelen of meer is de grootste uitdaging dan ook om het assortiment mét alle technische specificaties overzichtelijk te weergeven. Daarbij moet een gebruiker het eenvoudig en snel de juiste variant van een product kunnen vinden en bestellen.</p>
                <h2>Waaraan voldoet een goede webshop voor de technische handel?</h2>
                <p>
                    De wwwebsolution website is speciaal voor deze assortimenten ontwikkeld. Wat maakt een wwwebsolution webshop de ideale uitgangsbasis voor producenten en groothandels in de branche?
                    <ol>
                        <li>Standaard 2 productweergaves: op de website kan worden genavigeerd via productgroepen of – met behulp van filters – direct via producttegels.</li>
                        <li>Filteren op elk kenmerk: Op álle productkenmerken kan een filter worden geplaatst, zodat de klant direct het juiste product kan vinden.</li>
                        <li>Uitgebreid zoeken: Via de zoekbalk kan uitgebreid worden gezocht op naam, trefwoord of op artikelnummer. Via de dropdown kan direct op de suggesties worden doorgeklikt.</li>
                        <li>Projectlijsten en favorieten: Uw klanten kunnen via hun account eenvoudig lijsten aanmaken en zo de benodigdheden voor hun projecten slim bijhouden.</li>
                        <li>Prijzen, eenheden en staffels: Wat kost het product, per hoeveel stuks zitten ze in een doos, en hoeveel korting krijg je bij grote afnames? Met onze webshop zorgen we dat al deze informatie duidelijk op de website staat.</li>
                        <li>Samen sterk: Wanneer vele groothandels gebruik maken van wwwebsolutions kan er onderling makkelijk gehandeld worden en wellicht samen ingekocht worden.</li>
                        <li>Snelheid: Binnen enkele dagen kan de webshop online en bent u volledig operationeel, vraag om de mogelijkheden!</li>
                        <li>Assortiment: Stel vanuit het basis pakket (+- 60.000 artikelen) je eigen pakket op en vul het aan met je eigen merken en artikelen, of begin met 0 artikelen en stel je eigen webshop samen (vraag naar het waanzinnig goedkoop tarief).</li>
                    </ol>
                </p>
                <h2>Onze demo webshop</h2>
                <p>Wilt u meer zien? Neem dan een kijkje op onze <a href="https://metavak.brabanttools.nl/" target="_blank">demowebshop</a> en ontdek de (standaard) mogelijkheden van onze webshop oplossing waarin het productassortiment centraal staat.</p>
                <p>Voor meer informatie kunt u contact opnemen met wwwebsulutions via telefoonnummer <a href="tel:+31613053051">06-13053051</a> of het onderstaande formulier invullen.</p>
                <b-form @submit.prevent="sendMail()">
                    <b-form-group horizontal
                                  :label-cols="4"
                                  label="Naam"
                                  label-for="name">
                        <b-form-input id="name" v-model.trim="mail.name" placeholder="Uw naam"></b-form-input>
                    </b-form-group>
                    <b-form-group horizontal
                                  :label-cols="4"
                                  label="E-mail*"
                                  label-for="email">
                        <b-form-input id="email" v-model.trim="mail.email" type="email" placeholder="Uw e-mailadres" required></b-form-input>
                    </b-form-group>
                    <b-form-group horizontal
                                  :label-cols="4"
                                  label="Tel."
                                  label-for="phone">
                        <b-form-input id="phone" v-model.trim="mail.phone" placeholder="Uw telefoonnummer"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Ik wil meer informatie</b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import { mapGetters } from 'vuex'
    import * as types from '../store/mutation-types'

    export default {
        name: 'Home',
        data() {
            return {
                mail: { name: '', email: '', phone: '' }
            }
        },
        methods: {
            sendMail() {
                this.$store.commit(types.SET_MAIL_TO_SEND, this.mail)
                this.$store.dispatch('sendContactMail').then(() => {
                    if (this.isMailSent) {
                        this.$notify({ group: 'success', type: 'success', title: 'Succes', text: 'Email is verzonden! U hoort zo spoedig mogelijk van ons.' })
                    }
                })
            }
        },
        computed: {
            ...mapGetters({
                isMailSent: 'isMailSent'
            })
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

