import Router from 'vue-router'
import Home from '../components/Home.vue'
import About from '../components/About.vue'

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/about',
            name: 'About',
            component: About,
        },
    ]
})
